<template>
  <div class="HSK">
    <div class="banner">
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>BCT</span> Introduction</h3>
        </div>
        <p class="_jieshao">
          Business Chinese test (BCT), as a standardized test of international Chinese ability, focuses on the ability of non Chinese first language candidates to communicate in Chinese in real business or general work situations.
        </p>
        <p class="_jieshao">
          BCT test is divided into written test and oral test, and they are independent of each other. The written examination includes BCT (A) and BCT (B). The corresponding relationship between each level of BCT and international Chinese proficiency standard, European language common reference framework (CEFR), HSK and YCT is shown in the table below:
        </p>
        <div class="_table">
          <div class="_row _head">
            <div class="_col w16">HSK</div>
            <div class="_col w16">YCT</div>
            <div class="_col w16">BCT</div>
            <div class="_col w16">Vocabulary</div>
            <div class="_col w16">International Chinese Level</div>
            <div class="_col w16">Common European Framework of Reference for Languages（CEFR）</div>
          </div>
          <div class="_row">
            <div class="_col w16">
              <div class="nei">
                <div class="_colm">HSK（Level 6）</div>
                <div class="_colm">HSK（Level 5）</div>
                <div class="_colm">HSK（Level 4）</div>
                <div class="_colm">HSK（Level 3）</div>
                <div class="_colm">HSK（Level 2）</div>
                <div class="_colm">HSK（Level 1）</div>
                <div class="_colm"></div>
              </div>
            </div>
            <div class="_col w16">
              <div class="nei">
                <div class="_colm tb3"></div>
                <div class="_colm">YCT（Level 4）</div>
                <div class="_colm">YCT（Level 3）</div>
                <div class="_colm">YCT（Level 2）</div>
                <div class="_colm">YCT（Level 1）</div>
              </div>
            </div>
            <div class="_col w16">
              <div class="nei">
                <div class="nei">
                  <div class="_colm tb3">BCT（B）</div>
                  <div class="_colm tb3">BCT（A）</div>
                  <div class="_colm"></div>
                </div>
              </div>
            </div>
            <div class="_col w16">
              <div class="nei">
                <div class="_colm">5000O Or more</div>
                <div class="_colm">2500</div>
                <div class="_colm">1200</div>
                <div class="_colm">600</div>
                <div class="_colm">300</div>
                <div class="_colm">150</div>
                <div class="_colm">80</div>
              </div>
            </div>
            <div class="_col w16">
              <div class="nei">
                <div class="_colm tb2">Level 5</div>
                <div class="_colm">Level 4</div>
                <div class="_colm">Level 3</div>
                <div class="_colm">Level 2</div>
                <div class="_colm">Level 1</div>
                <div class="_colm"></div>
              </div>
            </div>
            <div class="_col w16">
              <div class="nei">
                <div class="_colm">C2</div>
                <div class="_colm">C1</div>
                <div class="_colm">B2</div>
                <div class="_colm">B1</div>
                <div class="_colm">A2</div>
                <div class="_colm">A1</div>
                <div class="_colm"></div>
              </div>
            </div>
          </div>

        </div>
        <div class="_detail">
          <div class="text">
            <div class="tit">1、 Capability description</div>
            <p class="txt">Candidates who pass BCT (A) can understand and use Chinese to complete daily communication and basic business communication tasks.</p>
            <p class="txt">Candidates who pass BCT (B) can understand and use Chinese to complete more complex business communication tasks.</p>
            <p class="txt">BCT (oral English) scores are divided into five ability levels. Candidates who pass the test can understand and use Chinese to complete various business communication tasks.</p>
            <div class="tit">2、 Examination principles</div>
            <p class="txt">Promoting learning through examination: stimulate the interest and confidence of Chinese learners, and further expand the scope of application of the examination according to the actual needs of overseas business Chinese learners and Chinese users in general working situations.</p>
            <p class="txt">Promote teaching through examination: provide teachers and self scholars with graded business Chinese task teaching resources and suggestions, so as to facilitate teaching and training, and improve business learners and users' ability to communicate in real business or general work situations in a planned and effective way.</p>
            <p class="txt">Positive feedback: through the examination of typical business language tasks, evaluate the candidates' ability to complete language tasks, and provide positive feedback for the candidates' next study.</p>
            <div class="tit">3、 Examination purpose</div>
            <p class="txt">BCT scores provide a reference for the following purposes:</p>
            <p class="txt">1. Provide standards for enterprises to evaluate employees (candidates) whose mother tongue is not Chinese and their ability to use Chinese for daily work; The examination results can be used as a reference for selection, employment and promotion.</p>
            <p class="txt">2. Provide reference for all kinds of schools and training institutions to recruit students majoring in business, as well as education and teaching links such as class division and credit granting.</p>
            <p class="txt">3. Provide reference for business Chinese learners to learn and evaluate their business Chinese ability.</p>
            <div class="tit">4、 Result report</div>
            <p class="txt">One month after the examination, candidates can log in to the Chinese examination service website www.chinesetest.cn to query the results. BCT performance report is issued by Hanban.</p>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="BCT（A）"
                         name="first">
              <div class="text">
                <p class="_jieshao">
                  Business Chinese test BCT (a) is a standardized international Chinese proficiency test, which focuses on the ability of Chinese non first language candidates to communicate in Chinese in real business or general work situations, and evaluates the language tasks they can complete. The examination follows the principles of application-oriented, listening and speaking oriented, ability oriented and positioning the workplace, strives to fit the actual situation of the workplace, and takes the practical language communication content as the carrier to test the candidates' ability to use Chinese to meet the needs of the working environment.
                </p>
                <div class="tit">1. Test Structure</div>
                <p class="txt">There are 70 questions in BCT (a), which is divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Test content</div>
                    <div class="_col w40">Number of questions</div>
                    <div class="_col w20">Time (Minutes)</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1.Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">20</div>
                    <div class="_col">About 20</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">30</div>
                    <div class="_col">30</div>
                  </div>
                  <div class="_row">
                    <div class="_col w40">3. Writing</div>
                    <div class="_col w40">
                      10
                    </div>
                    <div class="_col">10</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet（After listening test）</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col">30</div>
                    <div class="_col">70</div>
                    <div class="_col">About 65</div>
                  </div>
                </div>
                <p class="txt">About 70 minutes for all exams (including 5 minutes for candidates to fill in their personal information)</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 10 questions in total. Listen to each question twice. Each question is a word or phrase. A picture is provided on the test paper. The examinee judges right or wrong according to what he hears.</p>
                <p class="txt">The second part consists of 10 questions. Listen to each question twice. Each question is a sentence. Three pictures are provided on the test paper. Candidates choose the corresponding pictures according to what they hear.</p>
                <p class="txt">Part III, 10 questions in total. Listen to each question twice. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Three options are provided on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">Part one, 10 questions in total. For the first five questions, each question provides one or two sentences or two dialogues. There is a space in the sentence. Candidates should choose words from the options provided and fill in the blanks; For the last five questions, a form or an application passage is provided on the test paper. There are five spaces in the form or passage. Candidates should choose words from the options provided to fill in the blanks.</p>
                <p class="txt">The second part, a total of 20 questions. The test paper provides a series of reading materials, such as pictures, tables, paragraphs, chapters, etc. each reading material brings one to three questions. Candidates should choose answers from three options.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">10 questions in total. Each question provides a sentence with a space. Candidates are required to write the correct Chinese characters or words in the space according to the Pinyin prompt.</p>
                <div class="tit">2、 Ability description and content examples of business Chinese test BCT (A)</div>
                <p class="txt">Business Chinese test (BCT (A) attaches importance to examining the candidates' communicative competence in the actual Chinese application environment. The ability description and content examples are shown in the table below:</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w10">Subtest</div>
                    <div class="_col w45">Description of Capability </div>
                    <div class="_col w45">Content Examples</div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Listening</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Be able to understand articles closely related to work or life</div>
                        <div class="_colm tb2">Be able to understand basic figures and quantitative information in work or living environment</div>
                        <div class="_colm tb2">Be able to understand the brief introduction of others' own information in the working or living environment</div>
                        <div class="_colm tb2">Be able to understand the most basic instructions or requirements in the working or living environment</div>
                        <div class="_colm tb2">Be able to understand basic daily language in working or living environment</div>
                        <div class="_colm tb2">Be able to understand basic instructions or requirements in work or living environment</div>
                        <div class="_colm tb2">Be able to understand the introduction or explanation of other people's basic information in the working or living environment</div>
                        <div class="_colm tb2">Be able to understand the introduction or explanation of others in the working or living environment</div>
                        <div class="_colm tb2">Be able to understand the introduction or explanation of something in the working or living environment</div>
                        <div class="_colm tb2">Be able to understand short notice or arrangement in work or living environment</div>
                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Simple daily necessities and office supplies</div>
                        <div class="_colm tb2">The most basic contents in the visa form, such as name, passport number, etc. <br> Number (including date, time, price).</div>
                        <div class="_colm tb2">Such as name, age, country, company name, position, contact information, etc. <br>Such as interests, hobbies, physical condition, etc.</div>
                        <div class="_colm tb2">Such as calling, signing, etc.</div>
                        <div class="_colm tb2">Such as introduction, greetings, welcome, wishes, thanks, apologies, farewell, etc.</div>
                        <div class="_colm tb2">Such as asking price, payment method, etc.</div>
                        <div class="_colm tb2">Such as company, position, contact information, etc.</div>
                        <div class="_colm tb2">Such as physical condition, work condition, etc.</div>
                        <div class="_colm tb2">Such as commodity characteristics, price, location introduction, etc.</div>
                        <div class="_colm tb2">Such as meeting notice, route or schedule, etc.</div>
                      </div>
                    </div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Reading</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Be able to spell Pinyin and recognize basic Chinese characters, words, numbers, personal information, etc</div>
                        <div class="_colm tb4">Be able to read basic Chinese characters, words and sentences or short written materials, understand requirements, and obtain relevant information from short written materials</div>
                        <div class="_colm tb4">Be able to read short written materials common in daily life or work, understand the general idea and identify basic information; Be able to find the specific information required in the paragraphs with familiar subjects</div>
                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">
                          <p>Simple signs (such as toilets, supermarkets, subways, airports).</p>
                          <p>Simple locations (e.g. offices, hotel rooms) Menu or timetable, etc.</p>
                        </div>
                        <div class="_colm tb4">
                          <p>Such as simple charts, such as visa list, price list, income and expenditure statement, schedule, etc.</p>
                          <p>Simple terms in the workplace or living place</p>
                          <p>The most commonly used business cards, notices, notes, messages, SMS, etc. in daily work and life</p>
                        </div>
                        <div class="_colm tb4">
                          <p>Common expressions in business etiquette or formal occasions</p>
                          <p>Brief introduction of products, advertisements, etc</p>
                          <p>Brief weather forecast and traffic information</p>
                          <p>Notice, telephone record, message, note, SMS, email, calendar, etc</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Writing</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Can write basic Chinese characters by dictation</div>
                        <div class="_colm tb3">Be able to fill in forms as required</div>

                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2"></div>
                        <div class="_colm tb3">
                          <p>Simple forms, such as visa form, customs form, etc.</p>
                          <p>Simple company internal forms, such as false application form, reimbursement form, etc.</p>
                          <p>Personal calendar, write down personal daily life or work arrangements.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tit">3. Reference Vocabulary</div>
                <p class="txt">The business Chinese test BCT (a) is mainly based on the language ability and ability scale. On this basis, the reference vocabulary of the test is set according to the word frequency of a number of authoritative Chinese corpora and the actual situation of international Chinese teaching, with a total of 600 words.</p>
                <div class="tit">4. Score system</div>
                <p class="txt">BCT (A) score report of business Chinese test provides candidates with sub test scores and total scores, and there is no qualified score; The performance report also describes the abilities of candidates in different score segments.</p>
                <p class="txt">The full volume of business Chinese test BCT (A) includes three subtests of listening, reading and writing, with a full score of 300. The score system is shown in the table below:</p>
                <div class="_table"
                     style="width:600px">
                  <div class="_row _head">
                    <div class="_col"></div>
                    <div class="_col">Listening</div>
                    <div class="_col">Reading</div>
                    <div class="_col">Writing</div>
                    <div class="_col">Total</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Number of Questions</div>
                    <div class="_col">30</div>
                    <div class="_col">30</div>
                    <div class="_col">10</div>
                    <div class="_col">70</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Full score of sub-test</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                  </div>
                </div>
                <div class="tit">5. Examination principles</div>
                <p class="txt">Promoting learning through examination: stimulate the interest and confidence of Chinese learners, and further expand the scope of application of the examination according to the actual needs of overseas business Chinese learners and Chinese users in general working situations.</p>
                <p class="txt">Promote teaching through examination: provide teachers and self scholars with graded business Chinese task teaching resources and suggestions, so as to facilitate teaching and training, and improve business learners and users' ability to communicate in real business or general work situations in a planned and effective way.</p>
                <p class="txt">Positive feedback: through the examination of typical business language tasks, evaluate the candidates' ability to complete language tasks, and provide positive feedback for the candidates' next study.</p>
                <div class="tit">6. Examination purpose</div>
                <p class="txt">1. Provide standards for enterprises to evaluate employees (candidates) whose mother tongue is not Chinese and their ability to use Chinese for daily work; The examination results can be used as a reference for selection, employment and promotion.</p>
                <p class="txt">2. Provide reference for all kinds of schools and training institutions to recruit students majoring in business, as well as education and teaching links such as class division and credit granting.</p>
                <p class="txt">3. Provide reference for business Chinese learners to learn and evaluate their business Chinese ability.</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="BCT（B）"
                         name="second">
              <div class="text">
                <p class="_jieshao">
                  Business Chinese test BCT (b) is a standardized international Chinese proficiency test, which focuses on the ability of Chinese non first language candidates to communicate in Chinese in real business or general work situations, and evaluates the language tasks they can complete. The examination follows the principles of application-oriented, listening and speaking oriented, ability oriented and positioning the workplace, strives to fit the actual situation of the workplace, and takes the practical language communication content as the carrier to test the candidates' ability to use Chinese to meet the needs of the working environment.
                </p>
                <div class="tit">1. Test Structure</div>
                <p class="txt">The written test paper of business Chinese test BCT (b) has 92 questions, which is divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Test Content</div>
                    <div class="_col w40">Number of Question</div>
                    <div class="_col w20">Time (Minutes)</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">20</div>
                        <div class="_colm">15</div>
                        <div class="_colm">15</div>
                      </div>
                    </div>
                    <div class="_col">50</div>
                    <div class="_col">About 35</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">20</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">40</div>
                    <div class="_col">60</div>
                  </div>
                  <div class="_row">
                    <div class="_col w40">3.Writing</div>
                    <div class="_col w40">
                      2
                    </div>
                    <div class="_col">40</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling Answer Sheet（After Listening Test）</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">92</div>
                    <div class="_col">About 140</div>
                  </div>
                </div>
                <p class="txt">All exams take about 145 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 20 questions in total. Listen to each question once. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Each question on the test paper provides four options. Candidates choose the answer according to what they hear.</p>
                <p class="txt">The second part consists of 15 questions. Listen to each question once. Each question is a four sentence dialogue between two people. The third person asks a question according to the dialogue. Each question on the test paper provides four options. Candidates choose the answer according to what they hear.</p>
                <p class="txt">The third part, a total of 15 questions. Listen to each question once. Play 5 paragraphs with several questions after each paragraph. Each question on the test paper provides 4 options. Candidates choose the answers according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">Part one, 20 questions in total. The test paper provides a series of reading materials, such as pictures, tables, paragraphs, chapters, etc. each reading material brings several questions. Candidates should choose the answers from four options.</p>
                <p class="txt">The second part consists of 10 questions. There are two texts on the test paper. Each text has five spaces. Candidates choose the answers from the seven sentence options provided in combination with the context.</p>
                <p class="txt">Part III, 10 questions in total. The 10 questions are divided into two groups with 5 questions in each group. The test paper provides four paragraphs of materials and several questions or sentences. Candidates should judge which paragraph of materials the questions or sentences are related to.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">Part I, 1 question in total. The test paper shall be provided with materials in the form of words or charts. Candidates shall write a composition of no less than 80 words according to the requirements of the topic.</p>
                <p class="txt">Part II, 1 question in total. The test paper shall be provided with materials in the form of words or charts. Candidates shall write a composition of no less than 150 words according to the requirements of the topic.</p>
                <div class="tit">Ability description and content examples of business Chinese test BCT (B)</div>
                <p class="txt">BCT (b) attaches importance to examining the candidates' communicative competence in the actual Chinese application environment. The ability description and content examples are shown in the table below:</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w10">Subtest</div>
                    <div class="_col w45">Capability description</div>
                    <div class="_col w45">Content examples</div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Listening</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb3">Be able to understand the general introduction in work or living environment</div>
                        <div class="_colm tb4">Be able to understand basic instructions in work or living environment</div>
                        <div class="_colm tb3">Be able to understand the notice or arrangement in the working or living environment</div>
                        <div class="_colm">Be able to understand the news you know in the media</div>
                        <div class="_colm tb5">Understand meetings with specific goals</div>
                        <div class="_colm tb2">Be able to understand formal talks or meetings</div>
                        <div class="_colm tb4">Be able to understand the discussion or conversation about something in the working or living environment</div>
                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb3">
                          <p>Such as the instructions for the use of general daily equipment and supplies, such as the introduction to the basic information of others</p>
                          <p>Such as the introduction of the business company</p>
                          <p>Such as project and product introduction, etc</p>
                        </div>
                        <div class="_colm tb4">
                          <p>Such as the description of their own work</p>
                          <p>Such as training content related to their own work</p>
                          <p>Such as route description</p>
                          <p>Such as instructions for general daily equipment and supplies</p>
                        </div>
                        <div class="_colm tb3">
                          <p>Notice of appointment time, place and other aspects of daily life</p>
                          <p>Broadcasting in airports, stations, shopping malls, hospitals and other public places</p>
                          <p>Notice of meeting, work arrangement, schedule, etc</p>
                        </div>
                        <div class="_colm">Such as radio, television and other news</div>
                        <div class="_colm tb5">
                          <p>Call to leave a message or reply according to the message</p>
                          <p>Communicate with colleagues on work problems</p>
                          <p>Communicate with maintenance personnel on equipment problems</p>
                          <p>Communicate with after-sales service on product quality</p>
                          <p>Communicate with your boss about your career plan</p>
                        </div>
                        <div class="_colm tb2">
                          <p>Basic communication for attending business banquets and other activities</p>
                          <p>Interview candidates in their field of expertise or business scope</p>
                        </div>
                        <div class="_colm tb4">
                          <p>Such as other people's discussion on common topics or important current events</p>
                          <p>Such as simple evaluation of commodities, companies, etc</p>
                          <p>Such as the main views of the other party in business negotiations</p>
                          <p>Understand the basic attitude of customers, such as approval, opposition, complaint, etc</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Reading</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Be able to understand signs and signs in work or living environment</div>
                        <div class="_colm tb5">Be able to understand certain length and complex language materials, grasp the main idea, and master important facts and details</div>
                        <div class="_colm tb4">Be able to understand a certain length of language materials, grasp the text structure, and distinguish sentences that ensure the semantic and structural cohesion of the text according to the context</div>
                        <div class="_colm tb2">Be able to read a series of content related materials and find out key information</div>
                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">
                          <p>Conference room signs, shop signs, names of companies and institutions</p>
                          <p>Traffic signs, packaging warning signs, business card information</p>
                        </div>
                        <div class="_colm tb5">
                          <p>Find the information required in the form, such as timetable, schedule, meal list, telephone book, list, visa form, leave form, etc</p>
                          <p>Read and understand important information in illustrative and narrative materials, such as notes, emails, letters, notices, faxes, memos, travel guides, route descriptions, product instructions, workflow instructions, product descriptions, complaint letters and other formal letters, resumes, cover letters, contracts, project reports, work reports, business statements, rules and regulations, etc</p>
                        </div>
                        <div class="_colm tb4">For example, in the missing text structure of a sentence, select the most appropriate one from several sentences according to the context meaning and fill in the blank</div>
                        <div class="_colm tb2">
                          Such as making a list, writing a schedule, writing notes, writing notices, writing faxes, writing memos and analyzing charts
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="_row">
                    <div class="_col w10">Writing</div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Be able to objectively describe basic daily life, work or social topics or charts, and convey or express appropriate information</div>
                        <div class="_colm tb4">Be able to record or explain specific topics, write relevant documents or instructions, correctly reflect the objective situation and accurately express their views</div>

                      </div>
                    </div>
                    <div class="_col w45">
                      <div class="nei left">
                        <div class="_colm tb2">Such as making a list, writing a schedule, writing notes, writing notices, writing faxes, writing memos and analyzing charts</div>
                        <div class="_colm tb4">
                          Meeting minutes, description (such as brief route description, equipment use description or work process description), work letters (such as complaint letter, thank-you letter, invitation letter, etc.), resume, cover letter, report (such as investigation report, work report, etc.), contract text, or demonstration and Analysis on some topics
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tit">3. Reference Vocabulary</div>
                <p class="txt">The business Chinese test BCT (b) is mainly based on the language ability and ability scale. On this basis, the reference vocabulary of the test is set, with a total of 4000 words, based on the results of Chinese word frequency statistics in a number of authoritative corpora and the actual situation of business Chinese teaching.</p>
                <div class="tit">4. Score system</div>
                <p class="txt">The BCT (b) score report provides candidates with sub test scores and total scores, and there is no qualified score; The performance report also describes the abilities of candidates in different score segments.</p>
                <p class="txt">The written test of business Chinese test BCT (b) includes three subtests of listening, reading and writing, with a full score of 300. The score system is shown in the table below:</p>
                <div class="_table"
                     style="width:600px">
                  <div class="_row _head">
                    <div class="_col"></div>
                    <div class="_col">Listening</div>
                    <div class="_col">Reading</div>
                    <div class="_col">Writing</div>
                    <div class="_col">Total</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Number of Questions</div>
                    <div class="_col">50</div>
                    <div class="_col">40</div>
                    <div class="_col">2</div>
                    <div class="_col">92</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Full score of sub-test</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                    <div class="_col">100</div>
                  </div>
                </div>
                <div class="tit">5. Examination principles</div>
                <p class="txt">Promoting learning through examination: stimulate the interest and confidence of Chinese learners, especially for the actual needs of overseas business Chinese learners and Chinese users in general working situations, and further expand the scope of application of the examination. 。</p>
                <p class="txt">Promote teaching through examination: provide teachers and self scholars with graded business Chinese task teaching resources and suggestions, so as to facilitate teaching and training, and improve business learners' and users' Chinese communication ability in real business situations in a planned and effective way. 境中的汉语交际能力。</p>
                <p class="txt">Positive feedback: by examining typical business language tasks, evaluate candidates' ability to complete language tasks in business situations, and provide positive feedback for candidates' next learning.</p>
                <div class="tit">6. Examination purpose</div>
                <p class="txt">Provide standards for enterprises to evaluate employees (candidates) whose mother tongue is not Chinese and their ability to use Chinese for daily work; The examination results can be used as a reference for selection, employment and promotion.</p>
                <p class="txt">It provides a reference for all kinds of schools and training institutions to recruit students majoring in business, as well as education and teaching links such as class division and credit granting.</p>
                <p class="txt">It provides a reference for business Chinese learners to learn and evaluate their business Chinese ability.</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="BCT(Speaking)"
                         name="third">
              <div class="text">
                <p class="_jieshao">
                  Business Chinese test (BCT) is a standardized test of international Chinese ability. It focuses on the ability of Chinese non first language candidates to communicate in Chinese (oral) in real business or work situations, and evaluates the language tasks they can complete. BCT (oral English) adopts the form of computer semi adaptive examination, follows the principle of "use-oriented, ability oriented and positioning the workplace", and tests the oral expression ability that fits the actual situation of the workplace and the ability to use Chinese to meet the needs of the working environment according to the characteristics and workplace needs of different candidates.
                </p>
                <div class="tit">1. Test Structure</div>
                <div class="_table">
                  <div class="_row _head _h-z3">
                    <div class="_col">Test Content</div>
                    <div class="_col">Number of Questions</div>
                    <div class="_col">Time (Minutes)</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Part 1</div>
                    <div class="_col">4</div>
                    <div class="_col">2</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Part 2</div>
                    <div class="_col">4</div>
                    <div class="_col">2</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Part 3</div>
                    <div class="_col">3</div>
                    <div class="_col">5</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Part 4</div>
                    <div class="_col">3</div>
                    <div class="_col">5</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Part 5</div>
                    <div class="_col">1</div>
                    <div class="_col">6</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col z2">Login debugging and questionnaire survey (before the examination)</div>
                    <div class="_col">About 5</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">15</div>
                    <div class="_col">About 25</div>
                  </div>
                </div>
                <p class="txt">All examinations will take about 25 minutes (including the time for candidates to log in to the system, debug equipment and answer questionnaires).</p>
                <p class="txt">The first part consists of 4 questions. Listen to each question once and listen again. Each question is a question. Each question on the test paper provides a picture. Candidates can refer to the picture to answer the question.</p>
                <p class="txt">The second part consists of 4 questions. Listen to each question once and listen again. Each question is a question. Candidates answer the questions according to the questions.</p>
                <p class="txt">The third part consists of 3 questions. Listen to each question once and listen again. Each question is a scenario description and a requirement or question. Each question on the test paper provides a picture, and the examinee refers to the picture to answer.</p>
                <p class="txt">Part IV, 3 questions in total. Listen to each question once and listen again. Each question is a background note and a requirement or question. Candidates answer according to the requirements of the question.</p>
                <p class="txt">Part V, 1 question in total. Listen to the question once and listen again. The title is background description and requirements. Four pictures are provided on the test paper. Candidates will answer according to this group of pictures.</p>
                <div class="tit">2. Ability standard of business Chinese test BCT (oral)</div>
                <p class="txt">The business Chinese test (BCT) attaches importance to examining the candidates' communicative competence in the actual Chinese application environment. The competence standards are shown in Table 2:</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">theme</div>
                    <div class="_col w60">Task</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">personal information</div>
                    <div class="_col w60">Be able to briefly introduce basic personal information, such as name, nationality, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Route description</div>
                    <div class="_col w60">It can briefly explain the walking or driving route, location, such as where the toilet is</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Description of living conditions</div>
                    <div class="_col w60">Can briefly explain the daily life arrangement, such as daily living habits</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Personal events</div>
                    <div class="_col w60">Be able to briefly introduce what happened to yourself, such as simple work experience</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Others information</div>
                    <div class="_col w60">Can briefly introduce a person, such as the company, position, contact information, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Personal basic work information</div>
                    <div class="_col w60">Be able to briefly introduce personal basic work information, such as the company, position, contact information, etc</div>
                  </div>

                  <div class="_row ">
                    <div class="_col w40">Personal work information</div>
                    <div class="_col w60">Be able to briefly introduce personal work information, such as job responsibilities, scope of work, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Company information</div>
                    <div class="_col w60">Be able to briefly introduce the basic information of your company</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Company product information</div>
                    <div class="_col w60">Be able to briefly introduce the main products of your company</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Description of work</div>
                    <div class="_col w60">Be able to briefly explain the work arrangement, such as project schedule, meeting arrangement, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">General topics</div>
                    <div class="_col w60">Be able to briefly explain your views on topics of general interest, such as weather, hobbies, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Work related topics</div>
                    <div class="_col w60">Be able to briefly explain their views on the topics in their work, such as the evaluation of a product and the future development of the company</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Project information</div>
                    <div class="_col w60">Be able to briefly introduce the main business items of your company</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Work execution description</div>
                    <div class="_col w60">Be able to briefly explain to colleagues how to perform a daily work task</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Work related topics</div>
                    <div class="_col w60">Be able to talk about work-related topics, such as personal career goals and plans in the future</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">make a statement</div>
                    <div class="_col w60">Be able to make prepared speeches on topics of interest, such as interests and hobbies</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">make a speech</div>
                    <div class="_col w60">Be able to deliver speeches according to the occasion, such as toast, opening speech, thank-you speech, etc</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Work related topics</div>
                    <div class="_col w60">Be able to conduct work-related topics, such as reporting on work progress</div>
                  </div>
                  <div class="_row ">
                    <div class="_col w40">Work related topics</div>
                    <div class="_col w60">Be able to carry out work-related topics, such as explaining relevant situations according to charts</div>
                  </div>

                </div>
                <div class="tit">3. Reference vocabulary</div>
                <p class="txt">The business Chinese test BCT (spoken language) is mainly based on the language ability and ability scale. On this basis, the reference vocabulary of the test is set, with a total of 4000 words, based on the results of Chinese word frequency statistics in a number of authoritative corpora and the actual situation of business Chinese teaching.</p>
                <div class="tit">4. Score system</div>
                <p class="txt">Business Chinese test BCT (oral) score report, which provides candidates with each part of the score and total score, without qualified score; The performance report also describes the abilities of candidates in different score segments.</p>
                <p class="txt">The full volume of BCT (oral Chinese) consists of five parts, with a full score of 500. The score system is shown in Table 3:</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w14"></div>
                    <div class="_col w14">Part 1</div>
                    <div class="_col w14">Part 2</div>
                    <div class="_col w14">Part 3</div>
                    <div class="_col w14">Part 4</div>
                    <div class="_col w14">Part 5</div>
                    <div class="_col w14">Total</div>
                  </div>
                  <div class="_row">
                    <div class="_col w14">Number of question</div>
                    <div class="_col w14">4</div>
                    <div class="_col w14">4</div>
                    <div class="_col w14">3</div>
                    <div class="_col w14">3</div>
                    <div class="_col w14">1</div>
                    <div class="_col w14">15</div>
                  </div>
                  <div class="_row">
                    <div class="_col w14">Full score of Subtest</div>
                    <div class="_col w14">80</div>
                    <div class="_col w14">100</div>
                    <div class="_col w14">120</div>
                    <div class="_col w14">120</div>
                    <div class="_col w14">80</div>
                    <div class="_col w14">500</div>
                  </div>
                </div>
                <div class="tit">5. Examination principles</div>
                <p class="txt">Promote learning by examination: according to the actual needs of business Chinese learners and users, stimulate learning interest, enhance learning confidence and continuously improve learning effect by evaluation.</p>
                <p class="txt">Promote teaching through examination: provide teachers and self scholars with graded business Chinese task teaching resources and suggestions, so as to facilitate teaching and training, and improve business learners' and users' Chinese communication ability in real business situations in a planned and effective way.</p>
                <p class="txt">Positive feedback: by examining typical business language tasks, evaluate candidates' ability to complete language tasks in business situations, and provide positive feedback for candidates' next learning.</p>
                <div class="tit">6. Examination purpose</div>
                <p class="txt">1. Provide standards for enterprises to evaluate the ability of non Chinese first language employees (candidates) to use Chinese for daily work, and the examination results can be used as a reference basis for selection, employment and promotion.</p>
                <p class="txt">2. Provide reference basis for various schools and training institutions to recruit students majoring in business, teach in classes and grant credits.</p>
                <p class="txt">3. Provide reference for business Chinese learners to self-study and evaluate their business Chinese ability.</p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
._jieshao {
  font-size: 14px;
  line-height: 1.8;
  text-indent: 2em;
  margin-bottom: 10px;
}
._detail {
  margin-top: 40px;
  .el-tabs ::v-deep {
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .text {
    padding: 20px 0;
    .txt {
      text-indent: 2em;
      font-size: 14px;
      margin-top: 10px;
      color: #363636;
      &._t {
        font-weight: bold;
        text-indent: 0;
      }
    }
  }
}
._table {
  margin: 0 auto;
  margin-top: 20px;
  ._row {
    border: 1px solid #dedede;
    margin-bottom: -1px;
    overflow: hidden;
    display: flex;
    &._head {
      background-color: #f1f1f1;
    }
    ._col {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      text-align: center;
      margin-bottom: -1px;
      min-height: 40px;
      font-size: 14px;
      &:last-child {
        border-right: none;
      }
      &.w80 {
        width: 80%;
      }

      &.w75 {
        width: 75%;
      }
      &.w60 {
        width: 60%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.w45 {
        width: 45%;
      }
      &.w40 {
        width: 40%;
      }
      &.w20 {
        width: 20%;
      }
      &.w16 {
        width: 16.66667%;
      }
      &.w10 {
        width: 10%;
      }
      &.w14 {
        width: 14.285%;
      }
    }
    &._h-z3 {
      ._col {
        width: 33.333%;
        &.z2 {
          width: 66.6666%;
        }
      }
    }
  }
  .nei {
    width: 100%;
    ._colm {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dedede;
      text-align: center;
      height: 40px;
      padding: 0 10px;
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &.tb5 {
        height: 200px;
      }
      &.tb4 {
        height: 160px;
      }
      &.tb3 {
        height: 120px;
      }
      &.tb2 {
        height: 80px;
      }
    }
    &.left {
      ._colm {
        align-items: flex-start;
        text-align: left;
      }
    }
  }
}
</style>